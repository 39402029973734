.bg {
  background-color: #022836;
  height: 100%;
  min-height: 100vh;
}

.togo_winzig {
  font-size: 60px;
}

.togo_klein {
  font-size: 100px;
}

.togo_normal {
  font-size: 150px;
}

.togo_gross {
  font-size: 200px;
}

.togo_riessig {
  font-size: 250px;
}

.statistic_winzig {
  font-size: 20px;
}

.statistic_klein {
  font-size: 30px;
}

.statistic_normal {
  font-size: 40px;
}

.statistic_gross {
  font-size: 50px;
}

.statistic_riessig {
  font-size: 60px;
}

.score_winzig {
  font-size: 30px;
}

.score_klein {
  font-size: 40px;
}

.score_normal {
  font-size: 50px;
}

.score_gross {
  font-size: 60px;
}

.score_riessig {
  font-size: 70px;
}

.name_winzig {
  font-size: 30px;
}

.name_klein {
  font-size: 40px;
}

.name_normal {
  font-size: 45px;
}

.name_gross {
  font-size: 50px;
}

.name_riessig {
  font-size: 60px;
}

.tabletscore_winzig {
  font-size: 40px;
}

.tabletscore_klein {
  font-size: 55px;
}

.tabletscore_normal {
  font-size: 60px;
}

.tabletscore_gross {
  font-size: 65px;
}

.tabletscore_riessig {
  font-size: 70px;
}

.tabletscoreheader_winzig {
  font-size: 14px;
}

.tabletscoreheader_klein {
  font-size: 18px;
}

.tabletscoreheader_normal {
  font-size: 22px;
}

.tabletscoreheader_gross {
  font-size: 24px;
}

.tabletscoreheader_riessig {
  font-size: 26px;
}
